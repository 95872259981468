@import '../colors.sass'
@import '../variables.sass'

.notificationsGroup
	position: absolute
	bottom: 6vh
	right: 5vw
	z-index: 20
	height: auto
	width: 400px
	max-width: 90vw
	margin: 0 auto
	.toast
		width: 100% !important
		box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.5)
		background: $lightBlue
		margin-bottom: 25px
.toast
	width: 90%
	position: relative
	margin: 0 auto
	margin-top: 20px
	padding: 0 13px 
	height: 80px
	cursor: pointer
	background: $blue
	border-radius: 10px
	display: flex
	flex-direction: row
	align-items: center
	-webkit-box-sizing: border-box
	-moz-box-sizing: border-box
	box-sizing: border-box
	transition: all .05s ease-in-out
	border: 0px solid $gold
	img
		width: 60px
		height: 60px
		max-height: none
		border-radius: 50%
	.text
		padding-left: 13px
		text-align: left
		.title
			font-weight: 500
			font-size: 20px
			line-height: 23px
			color: $gold
		.description
			font-weight: 400
			font-size: 15px
	.actions
		margin-left: auto
		svg
			fill: $white
			transform: scale(1.1)
			margin-left: 10px
			transition: all .2s ease-in-out
			&:hover
				transform: scale(1.3)
		.delete
			fill: $red
	.click
		width: 100%
		height: 100%
		position: absolute
		top: 0
		left: 0
	&:hover
		border: 3px solid $gold

.toast.small
	width: 90%

		

@media screen and (max-width: $smartphone)
	.notificationsGroup		
		left: 50%
		transform: translateX(-50%)
	.toast
		width: 90%
		.text
			.title
				font-size: 2vh
			.description
				font-size: 1.6vh
	.toast.small
		width: 95%