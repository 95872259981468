/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/open-sans/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/open-sans/open-sans-v34-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../assets/fonts/open-sans/open-sans-v34-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans/open-sans-v34-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/open-sans/open-sans-v34-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans/open-sans-v34-latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-500 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/open-sans/open-sans-v34-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/open-sans/open-sans-v34-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans/open-sans-v34-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans/open-sans-v34-latin-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/open-sans/open-sans-v34-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans/open-sans-v34-latin-500.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/open-sans/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/open-sans/open-sans-v34-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans/open-sans-v34-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans/open-sans-v34-latin-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/open-sans/open-sans-v34-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans/open-sans-v34-latin-600.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-500italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/open-sans/open-sans-v34-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../assets/fonts/open-sans/open-sans-v34-latin-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../assets/fonts/open-sans/open-sans-v34-latin-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans/open-sans-v34-latin-500italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/open-sans/open-sans-v34-latin-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans/open-sans-v34-latin-500italic.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
