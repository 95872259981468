// @import url("https://use.typekit.net/rok0ypj.css")

//Variables
@import './colors.sass'
@import './variables.sass'
@import './components/toast.sass'

body, html, *
	font-family: 'Open Sans', "San Francisco", 'Roboto', sans-serif
	font-weight: 400
	font-style: normal
	color: $lightGrey
	margin: 0
	padding: 0
	i
		font-style: italic

body, html
	width: 100%
	height: 100%
	background: $blue
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

#root
	position: relative
	height: 100%
	width: 100%
	top: 0
	left: 0

#content
	position: absolute
	height: 100vh
	width: 100vw
	max-height: 100%
	max-width: 100%
	z-index: 0

.navEx
	margin-top: 10vh

.redButton, .greenButton, .blueButton
	height: 50px
	width: auto
	padding: 0 50px
	border: none
	border-radius: 10px
	font-size: 18px
	cursor: pointer
	color: $white

.redButton
	background: $red

.greenButton
	background: $green

.blueButton
	background: $blue

.redButton.medium, .greenButton.medium, .blueButton.medium
	width: 70%

.disabled
	cursor: no-drop
	filter: saturate(30%)

.scroll
	word-wrap: break-word
	flex: 0 1 auto
	overflow-y: auto
	-webkit-box-sizing: border-box
	-moz-box-sizing: border-box
	box-sizing: border-box
	height: 100%

.alert
	position: absolute
	width: 100vw
	height: 100vh
	max-width: 100%
	max-height: 100%
	background: rgba(0, 0, 0, .8)
	z-index: 19
	.text
		position: absolute
		top: 50%
		left: 50%
		min-width: 30vw
		min-height: 30vh
		transform: translate(-50%, -50%)
		display: flex
		flex-direction: column
		z-index: 100
		background: $blue
		justify-content: center
		align-items: center
		border-radius: 20px
		h2,button
			margin: 20px auto
		h2
			color: $red
		button
			width: auto
			max-width: 60%

::-webkit-scrollbar
	width: 7px
	height: 7px

/* Track *
::-webkit-scrollbar-track
	background: none

/* Handle *
::-webkit-scrollbar-thumb
	background: rgba(242, 177, 56, 0.8)
	border-radius: 10px

/* Handle on hover *
::-webkit-scrollbar-thumb:hover
	background: rgba(202, 146, 43, 0.74)

::-webkit-scrollbar-button
	transform: translateY(50px)
	height: 5px

@media screen and (max-width: $smartphone)
	.redButton, .greenButton, .blueButton
		font-size: 16px
		padding: 0 5vw
		span
			display: none
